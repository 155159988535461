<style>
    p{margin:0;}
    .source-material {
        margin: 0 30px;
        border-radius: 10px;
        overflow: hidden;
        flex-shrink: 0;
        background-color: #fefefe;
    }

    .source-material-img {
        height: 142px;
        overflow: hidden;
        position: relative;
    }

    .source-material-title {
        position: absolute;
        left: 16px;
        top: 10px;
        right: 16px;
        font-size: 1.22rem;
        color: #fff;
    }

        .source-material-title span {
            text-shadow: 1px 1px 0 #333;
        }

    .source-material-img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .source-material-hd {
        padding: 14px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .source-material-but {
        margin-left: 10px;
        flex-shrink: 0;
    }

    .source-material-hd-time {
        color: #444;
        margin-bottom: 10px;
    }

    .source-material-hd-sub {
        color: #999;
        font-size: 0.72rem;
    }

    .source-material-bri {
        padding: 0 10px;
        margin: 0;
    }

    .source-material-fd {
        padding: 2px 10px 10px;
        font-size: 0.72rem;
        color: #999;
    }
    .source-active{overflow:hidden;}
   
    .source-active-title {
        font-size: 1.1rem;
    }

    .source-active-but {
        display: none;
        color: #333
    }

    .source-active:hover .source-active-title {
        color: #0094ff;
    }

    .source-active:hover .source-active-but {
        display: block;
    }
    
    .flex-overflow {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .source-active-p {
        height: 46px;
        width: 100%;
        line-height:22px;
        overflow: hidden;
        white-space:normal;
        margin-bottom:8px;
        text-align:justify;
    }
</style>
<template>
    <div style="height:100%;background-color:#fff;overflow-x:hidden;">
        <a-layout-content style="height:100%;background-color:#fff;">
            <div style="background-color:#fff;">
                <a-form layout="horizontal" style="padding:10px 20px 0;height:auto;">
                    <a-form>
                        <a-form-item>
                            <a-tabs default-active-key="0">
                                <a-tab-pane key="0" tab="素材包详情" />
                            </a-tabs>
                        </a-form-item>
                    </a-form>
                </a-form>
                <a-row type="flex" style="flex-wrap:nowrap;">
                    <a-col flex="400px">
                        <div class="source-material">
                            <div class="source-material-img">
                                <div class="source-material-title"><span>{{info.Name}}</span></div>
                                <img slot="cover" style="height:150px; object-fit:cover;" :src="Getsrc(info)" />
                                <!---src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"--->
                            </div>
                            <div class="source-material-hd">
                                <div>
                                    <div class="source-material-hd-time"> {{GetPackageRule(info)}}</div>
                                    <div class="source-material-hd-sub">已订阅 {{info.Subscription.length }}</div>
                                </div>
                                <div class="source-material-but">
                                    <a-button type="danger" shape="round" ghost @click="EditSubscription(info)">{{info.Subscriptionbool?'取消订阅':'订阅' }}</a-button>
                                </div>
                            </div>
                            <p class="source-material-bri">简介</p>
                            <div class="source-material-fd" v-html="info.Summary">

                            </div>
                        </div>

                        <!--<a-card hoverable style="height:350px; margin:30px" :key="info.ID">
                            <img slot="cover" :src="Getsrc(info)" style="height:150px" />
                            <a-card-meta :title="info.Name">
                                <template slot="description">
                                    {{GetPackageRule(info)}}
                                    <br />
                                    已订阅{{info.Subscription.length }}
                                    <br />
                                    简介
                                    <br />
                                    <p v-html="info.Summary"></p>
                                    <a-button style="float:right;" @click="AddSubscription(info)">{{info.Subscriptionbool?'取消订阅':'订阅' }}</a-button>
                                </template>
                            </a-card-meta>
                        </a-card>-->
                    </a-col>
                    <a-col flex="auto" style="padding-left:30px;border-left:1px solid #e5e5e9;overflow:hidden;" >
                        <div>素材包内容</div>
                        <div style="margin:14px 0;">
                            <a-row :gutter="32">
                                <a-col :xxl="12" :lg="24" :key="info.ID" v-for="info in tableData" class="source-active">
                                    <a-row type="flex" style="flex-wrap:nowrap;">
                                        <a-col flex="250px" style="padding:10px 0;">
                                            <img :src="Getsrc(info)" style="height:130px;width:100%;object-fit:cover;border-radius:8px;" />
                                        </a-col>
                                        <a-col flex="auto" style="padding:8px 20px;overflow:hidden;flex-shrink:0;flex:1;">
                                            <h3 class="source-active-title flex-overflow">{{info.Name}}</h3>
                                            <a-row type="flex" align="middle" style="flex-wrap:nowrap;">
                                                <a-col flex="auto" style="overflow:hidden;">
                                                    <p v-html="info.ContextText" class="flex-overflow source-active-p"></p>
                                                    <p class="flex-overflow">创建时间：{{TimeStamptoDateTime(info.InputTime)}}</p>
                                                    <p class="flex-overflow">分享时间：{{TimeStamptoDateTime(info.ShareTime)}}</p>
                                                </a-col>
                                                <a-col flex="200" style="margin-left:30px" class="source-active-but"> <a-button type="primary" @click="ShowDetail(info)">推送</a-button></a-col>
                                            </a-row>


                                        </a-col>

                                    </a-row>

                                </a-col>

                            </a-row>

                            <!--<a-card hoverable style="width: 440px;float:left;height:300px; margin:30px" :key="info.ID" v-for="info in tableData">
                                <img slot="cover" :src="Getsrc(info)" style="height:150px" />
                                <a-card-meta :title="info.Name">
                                    <template slot="description">

                                    </template>
                                </a-card-meta>
                            </a-card>-->

                        </div>

                        <div style="width:100%;padding-bottom:20px;">
                            <a-pagination v-model="pagination.current" :total="pagination.total" :defaultPageSize="pagination.defaultPageSize" :showTotal="pagination.showTotal" @change="pagerChange" />
                        </div>
                    </a-col>
                </a-row>


            </div>
        </a-layout-content>
        <div v-if="DetailVisible">
            <Detail :closeDetail="closeDetail" :MaterialType="MaterialType" :ID="ID" :PushID="PushID" :IDType="1" :disabled=false></Detail>
        </div>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    import Detail from '@/Views/MaterialModule/M2Material/Detail';
    export default {
        name: "PackageDetail",
        data() {
            return {
                info: {
                    ImgTopic: "",
                    Subscription: []
                },
                PackageID: 0,
                tableData: [],
                pagination: {
                    total: 0,
                    defaultPageSize: 10,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                MaterialType: -1,
                PushID: "",
                ID: "0",
                DetailVisible: false,
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            Getsrc: function (info) {
                var rt = "";
                if (info.ImgTopic != null) {
                    rt = info.ImgTopic.replace(/upload\//g, 'upload2/');
                }
                return rt;
            },
            getinfo: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/GetMaterialPackageList",
                    data: {
                        pageIndex: 1,
                        PackageID: self.PackageID
                    },
                    OnSuccess: function (data) {
                        self.info = data.data.Data[0];
                    }
                };
                http.Post(op);
            },
            EditSubscription(e) {
                var self = this;
                var con = e.Subscriptionbool ? "取消订阅" : "订阅";
                this.$confirm({
                    title: "提示",
                    content: "是否" + con + e.Name,
                    okText: "确定",
                    cancelText: "取消",
                    onOk() {
                        var Status = e.Subscriptionbool ? 0 : 1;
                        var op = {
                            url: "/MarketingModule/Material/EditSubscription",
                            data: {
                                PackageID: e.ID,
                                Status: Status
                            },
                            OnSuccess: function (data) {
                                console.log(data);
                                self.getinfo();
                                self.$message.success(con + "成功");
                            },
                        };
                        http.Post(op);
                    },
                    onCancel() { },
                });
            },
            GetPackageRule: function (info) {
                var rt = "";
                if (info.PackageRule == null) {
                    rt = "未配置";
                } else {
                    switch (info.PackageRule.PublishFrequency) {
                        case 0:
                            rt = "";
                            break;
                        case 1:
                            rt = "每日AM" + info.PackageRule.PublishTime + "推送";
                            break;
                        case 2:
                            var List = [];
                            info.PackageRule.PackageRuleItem.forEach(e => {
                                List.push(e.PeriodIndex);
                            });
                            var PackageRuleItem = List.join('、');
                            PackageRuleItem = PackageRuleItem.replace("0", "日").replace("1", "一").replace("2", "二").replace("3", "三").replace("4", "四").replace("5", "五").replace("6", "六");
                            rt = "每周" + PackageRuleItem + "AM" + info.PackageRule.PublishTime + "推送";
                            break;
                    }
                }
                return rt;
            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/GetMaterialQueueList",
                    data: {
                        MaterialType: -1,
                        PageIndex: self.pagination.current,
                        PackageID: self.PackageID
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item;
                self.getTableData();
            },
            TimeStamptoDateTime: function (e) {
                return util.TimeStamptoDateTime(e, 'yyyy-MM-dd hh:mm:ss');
            },
            ShowDetail(e) {
                var self = this;
                self.MaterialType = e.MaterialType;
                self.PushID = (e.ID).toString();
                self.DetailVisible = true;
            },
            closeDetail(e) {
                console.log(e);
                var self = this;
                self.DetailVisible = false;
            },
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
            Detail
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
            this.PackageID = this.$route.query.id;
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.getinfo();
            this.getTableData();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>